export const SET_STAGE_INFO = 'SET_STAGE_INFO';
export const SET_LINK_TYPE = 'SET_LINK_TYPE'
export const SET_STAGE_REASON = 'SET_STAGE_REASON';
export const SET_CLIENT_DATA = 'SET_CLIENT_DATA';
export const SET_ORDER_DATA = 'SET_ORDER_DATA';
export const SET_STAGE_TOKEN = 'SET_STAGE_TOKEN';
export const SET_BANKID_LINK = 'SET_BANKID_LINK';
export const SET_ERROR = 'SET_ERROR';
export const SET_STEP_OF_PROGRESS = 'SET_STEP_OF_PROGRESS';
export const SET_OVERLAY_PRELOADER = 'SET_OVERLAY_PRELOADER';
export const SET_SUBSTATE = 'SET_SUBSTATE';
export const SET_SUBSTATE_REASON = 'SET_SUBSTATE_REASON';
export const SET_NPS_DATA = 'SET_NPS_DATA';
export const SET_NPS_QUESTION = 'SET_NPS_QUESTION';
export const SET_NPS_STAGE = 'SET_NPS_STAGE';
export const SET_STOPPED = 'SET_STOPPED';
export const SET_STOP_REASON = 'SET_STOP_REASON';