import Vue from 'vue'
import Vuex from 'vuex'
import {TK_API} from '@/API/API.js'

Vue.use(Vuex)

import {
    SET_STAGE_INFO,
    SET_CLIENT_DATA,
    SET_ORDER_DATA,
    SET_STAGE_TOKEN,
    SET_BANKID_LINK,
    SET_ERROR,
    SET_STEP_OF_PROGRESS,
    SET_OVERLAY_PRELOADER,
    SET_STAGE_REASON,
    SET_SUBSTATE,
    SET_SUBSTATE_REASON,
    SET_LINK_TYPE,
    SET_NPS_DATA,
    SET_NPS_QUESTION, SET_NPS_STAGE, SET_STOP_REASON, SET_STOPPED
} from '@/store/mutation-types';


export default new Vuex.Store({
    state: {
        error: '',
        clientData: {
            first_name: '',
            last_name: '',
            mid_name: '',
            itn: '',
            phone: {},
        },
        orderData: {
            onetime_fee: 0,
            value: 0,
            periods: 0,
            grace_period_length: 0,
            monthly_payment_in_grace_period: 0,
            monthly_payment: 0,
            next_payment_date: '',
            last_payment_date: '',
            is_product_changed_by_erp: false,
            grace_period_from: null,
            grace_period_to: null
        },
        link: {
            linkType: ''
        },
        stop: {
            is_stopped: false,
            stop_reason: ''
        },
        stage: {
            code: '',
            id: 0,
            name: '',
            token: '',
            BankIdLink: '',
            reason: '',
            substate: '',
            substateReason: ''
        },
        nps_data: {
            topic: '',
            theme: '',
            welcome_message: '',
            step: { current: 0, of: 0 },
            current_question: {
                token: '',
                handler: '',
                text: '',
                question_type: '',
                variants: []
            },
            stage: { code: '', reason: '' }
        },
        step: {
            current: 0,
            of: 0,
        },
        showWaitingOverlayPreloader: false,
        waitingOverlayCustomText: ''
    },
    getters: {
        clientDataFilled(state) {
            return state.clientData.first_name !== ''
                && state.clientData.last_name !== ''
                && state.clientData.mid_name !== ''
                && state.clientData.itn !== ''
        },
        clientError(state) {
            return state.error !== ''
        },
        showWaitingOverlayPreloader(state) {
            return state.showWaitingOverlayPreloader;
        },
        waitingText(state) {
            return state.waitingOverlayCustomText;
        },
    },
    mutations: {
        [SET_STAGE_INFO](state, payload) {
            if (state.stage.code === '') {
                state.stage.code = payload;

                // state.stage.id = payload.new_stage.id;
                // state.stage.name = payload.new_stage.name;
            }
        },
        [SET_LINK_TYPE](state, payload) {
            if (state.link.linkType === '') {
                state.link.linkType = payload;
            }
        },
        [SET_STAGE_TOKEN](state, token) {
            if (state.stage.token === '') {
                state.stage.token = token;
            }
        },
        [SET_BANKID_LINK](state, token) {
            if (state.stage.BankIdLink === '') {
                state.stage.BankIdLink = token;
            }
        },
        [SET_STEP_OF_PROGRESS](state, payload) {
            state.step.current = payload.current;
            state.step.of = payload.of;
        },
        [SET_STAGE_REASON](state, payload) {
            state.stage.reason = payload;
            },
        [SET_CLIENT_DATA](state, payload) {
            if (!payload.isclientDataFilled) {
                state.clientData = Object.assign(state.clientData, payload.data);
                state.stop = {
                    is_stopped: payload.data.is_stopped,
                    stop_reason: payload.data.stop_reason
                }
            }
        },
        [SET_ORDER_DATA](state, payload) {
            if (!payload.isclientDataFilled) {
                const stateObjectKeys = Object.keys(state.orderData);
                const orderArray = Object.entries(payload.data);
                let coppiedArray = [...orderArray];
                coppiedArray = coppiedArray.filter((el) => stateObjectKeys.includes(el[0]))
                state.orderData = Object.assign(state.orderData, Object.fromEntries(coppiedArray));
            }
        },
        [SET_ERROR](state, {error}) {
            if (state.error === '') {
                state.error = error;
            }
        },
        [SET_OVERLAY_PRELOADER](state, payload) {
            state.showWaitingOverlayPreloader = payload.showWaitingOverlayPreloader;
            if(payload.waitingText) {
                state.waitingOverlayCustomText = payload.waitingText;
            } else {
                state.waitingOverlayCustomText = "зачекайте..."
            }
        },
        [SET_SUBSTATE](state, payload) {
            state.stage.substate = payload
        },
        [SET_SUBSTATE_REASON](state, payload) {
            state.stage.substateReason = payload
        },
        [SET_NPS_DATA](state, payload) {
            state.nps_data.theme = payload.theme
            state.nps_data.topic = payload.topic
            state.nps_data.welcome_message = payload.welcome_message
            state.nps_data.step = { current: payload.current_question_number, of: payload.questions_count }
        },
        [SET_NPS_QUESTION](state, payload) {
            state.nps_data.current_question.question_type = payload.question_type
            state.nps_data.current_question.token = payload.token
            state.nps_data.current_question.text = payload.text
            state.nps_data.current_question.handler = payload.handler
            state.nps_data.current_question.variants = payload.variants
        },
        [SET_NPS_STAGE](state, payload) {
            state.nps_data.stage.code = payload.substate
            state.nps_data.stage.reason = payload.substateReason
        },
        [SET_STOPPED](state, payload) {
            state.stop.is_stopped = payload
        },
        [SET_STOP_REASON](state, payload) {
            state.stop.stop_reason = payload
        }
    },
    actions: {
        checkTokenStage({commit}, invToken) {
            return new Promise((resolve, reject) => {
                TK_API.checkTokenStage(invToken)
                    .then((data) => {
                        if (data.success === true) {
                            commit(SET_STAGE_REASON, data.payload.stage.reason)
                            commit(SET_STAGE_INFO, data.payload.stage.code);
                            commit(SET_STAGE_TOKEN, invToken);
                            commit(SET_STEP_OF_PROGRESS, data.payload.step);
                            commit(SET_SUBSTATE, data.payload.stage.substate);
                            commit(SET_STOPPED, data.payload.is_stopped);
                            commit(SET_STOP_REASON, data.payload.stop_reason)
                            resolve(data.payload);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                        commit(SET_ERROR, {error: 'checkTokenStage'});
                        console.error(err);
                    });
            });
        },
        activateInvitation({commit}, invToken) {
            return new Promise((resolve, reject) => {
                TK_API.activateInvitation(invToken)
                    .then((data) => {
                        if (data!==undefined && data.success === true) {
                            localStorage.setItem('VUE_APP_X_TOKEN', data.payload.session_id)
                            commit(SET_LINK_TYPE, data.payload.link_type)
                            commit(SET_STAGE_INFO, data.payload.new_stage.code);
                            commit(SET_STEP_OF_PROGRESS, data.payload.step)
                            let timer = setTimeout(
                                () => {
                                    resolve(data.payload);
                                    clearTimeout(timer)
                                }, 500)
                        } else {
                            reject({});
                            commit(SET_ERROR, {error: 'activateInvitation'});
                        }
                    })
                    .catch((err) => {
                        reject(err);
                        commit(SET_ERROR, {error: 'activateInvitation'});
                        console.error(err);
                    });
            });
        },
        getInvitationData({commit}, payload) {
            commit(SET_OVERLAY_PRELOADER, {showWaitingOverlayPreloader: true});
            return new Promise((resolve, reject) => {
                TK_API.getInvitationData(payload.invToken)
                    .then((data) => {
                        if (data.success === true) {
                            let link_type = data.payload.link_type
                            commit(SET_LINK_TYPE, link_type)
                            if (link_type === 'nps') {
                                commit(SET_NPS_DATA, data.payload.nps_data);
                                commit(SET_NPS_QUESTION, data.payload.nps_data.current_question)
                                commit(SET_NPS_STAGE, data.payload.nps_data.stage)
                            } else if (link_type === 'invitation_link') {
                                if(data.payload.invitation_data.order_pk!==null && data.payload.invitation_data.order_pk!==undefined) {
                                    commit(SET_CLIENT_DATA,
                                        {
                                            data: {
                                                ...data.payload.invitation_data.order_pk.client,
                                                phone: data.payload.invitation_data.order_pk.phone,
                                                is_stopped: data.payload.invitation_data.is_stopped,
                                                stop_reason: data.payload.invitation_data.stop_reason
                                            },
                                            isclientDataFilled: payload.clientDataFilled
                                        });
                                    commit(SET_ORDER_DATA,
                                        {
                                            data: data.payload.invitation_data.order_pk,
                                            isclientDataFilled: payload.clientDataFilled
                                        });
                                } else if(data.payload.invitation_data!==null) {
                                    commit(SET_CLIENT_DATA,
                                        {
                                            data: {
                                                is_stopped: data.payload.invitation_data.is_stopped,
                                                stop_reason: data.payload.invitation_data.stop_reason
                                            },
                                            isclientDataFilled: payload.clientDataFilled
                                        });
                                }
                            }
                        }
                        resolve(data.payload)
                    })
                    .catch((err) => {
                        reject(err);
                        commit(SET_ERROR, {error: 'getInvitationData'});
                    })
                    .finally(() => {
                        commit(SET_OVERLAY_PRELOADER, {showWaitingOverlayPreloader: false});
                    });
            });
        },
        updateCurrentStage({commit}, {invToken, action, code = ''}) {
            return new Promise((resolve, reject) => {
                TK_API.updateTokenStage(invToken, action, code)
                    .then((data) => {
                        if (data.success === true) {
                            const response = data.payload.return.action
                            switch (action) {
                                case "continue":
                                    commit(SET_STAGE_INFO, response.value.stage.code);
                                    commit(SET_STEP_OF_PROGRESS, response.value.step);
                                    commit(SET_SUBSTATE, response.value.stage.substate);

                                    break;
                                case "get_bank_id_link":
                                    commit(SET_BANKID_LINK, data.payload.return.link);
                                    break;
                            }
                            resolve(data)
                        }
                    })
                    .catch((err) => {
                        reject(err);
                        console.error(err);
                    });
            });
        },


        // eslint-disable-next-line no-empty-pattern
        getBankIdVerification({}, sid) {
            return new Promise((resolve, reject) => {
                TK_API.getBankIdVerification(sid)
                    .then((data) => {
                        if (data.success === true) {
                            resolve(data);
                        }
                    })
                    .catch((err) => {
                        reject(err);
                        console.error(err);
                    });
            });
        },
        // eslint-disable-next-line no-empty-pattern
        signOrderWithOtp({}, {invToken, code}) {
            return new Promise((resolve) => {
                TK_API.signOrderWithOtp(invToken, code)
                    .then((data) => {
                        if (data.success === true) {
                            resolve(data);
                        }
                    })
                    .catch(() => {
                        resolve(false);
                    });
            });
        },
        // eslint-disable-next-line no-empty-pattern
        getInvitationFiles({}, invToken) {
            return new Promise((resolve) => {
                TK_API.getInvitationFiles(invToken)
                    .then((data) => {
                        if (data.success === true) {
                            resolve(data.payload.files)
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },
        // eslint-disable-next-line no-empty-pattern
        getFile({}, {invToken, fileToken}) {
            return new Promise((resolve) => {
                TK_API.getFile(invToken, fileToken)
                    .then((data) => {
                        resolve(data)
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },

        // eslint-disable-next-line no-empty-pattern
        requestNewOtp({}, invToken) {
            return new Promise((resolve) => {
                TK_API.getNewOtp(invToken)
                    .then((data) => {
                        if (data.success === true) {
                            resolve(data.payload.files)
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },
        // eslint-disable-next-line no-empty-pattern
        createLinkForApplicationFormCacheOnline({}, payload) {
            return new Promise((resolve) => {
                TK_API.createLinkForApplicationFormCacheOnline(payload)
                    .then((data) => {
                        const tokenForRedirect = data.data.payload.link;
                        resolve(tokenForRedirect);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },
        // eslint-disable-next-line no-empty-pattern
        getCreditCalculatorLimitsAction({}, token) {
            return new Promise((resolve) => {
                TK_API.getCreditCalculatorLimits(token)
                    .then((data) => {
                        const calculatorProperties = data.payload.properties;
                        resolve(calculatorProperties);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },
        // eslint-disable-next-line no-empty-pattern
        sendCalculatorSettingsForResult({}, payload) {
            return new Promise((resolve) => {
                TK_API.sendCalculatorSettingsForResult(payload)
                    .then((data) => {
                        const results = data.payload;
                        resolve(results);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },

        submitCreditValues({commit}, payload) {
            return new Promise((resolve) => {
                TK_API.submitCreditValues(payload)
                    .then((data) => {
                        const stage = data.payload.return.action.value.stage.code;
                        const stepOf = data.payload.return.action.value.stage.code;
                        commit(SET_STAGE_INFO, stage);
                        commit(SET_STEP_OF_PROGRESS, stepOf);
                        resolve(stage)
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },

        // eslint-disable-next-line no-empty-pattern
        getIpayLink({}, payload) {
            return new Promise((resolve) => {
                TK_API.getIpayLink(payload)
                    .then((data) => {
                        const ipayLink = data.payload;
                        resolve(ipayLink);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        },

        // eslint-disable-next-line no-empty-pattern
        sendPhoneNumberToCheck({}, payload) {
            return new Promise((resolve) => {
                TK_API.sendPhoneNumberToCheck(payload)
                    .then((data) => {
                        const result = data.payload
                        resolve(result)
                    })
                    .catch((err) => {
                        console.error(err)
                    });
            });
        },

        // eslint-disable-next-line no-empty-pattern
        sendPhoneConfirmationAction({}, payload) {
            return new Promise((resolve) => {
                TK_API.sendPhoneConfirmationAction(payload)
                    .then((data) => {
                        const result = data.success
                        resolve(result)
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            })
        },

        // eslint-disable-next-line no-empty-pattern
        applyNpsAction({}, payload) {
            return new Promise((resolve) => {
                TK_API.sendNpsAcceptedAction(payload)
                    .then((data) => {
                        const  result = data.success
                        resolve(result)
                    })
                    .catch((err) => {
                        console.error(err)
                    })
            })
        },

        // eslint-disable-next-line no-empty-pattern
        getJoinByRefData({}, payload) {
            return new Promise((resolve) => {
                TK_API.getJoinByRefData(payload)
                    .then((data) => {
                        const result = data.payload;
                        resolve(result)
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        },

        // eslint-disable-next-line no-empty-pattern
        getFinMonPagePayload({}, payload) {
            return new Promise((resolve) => {
                TK_API.getFinMonPayload(payload)
                    .then((data) => {
                        const finMonPage = data.payload;
                        resolve(finMonPage);
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            });
        }
    }
});

