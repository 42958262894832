<template>
  <div class="header">
    <div class="logoWrapper">
      <img src="https://cdn-do.sfactor.com.ua/sf-corpsite/web/logo_-___-01 (1).svg" alt="logoSF"/>
    </div>
<!--    <a v-if="isRouteIncludesPhoneBtn(this.$route.name)" class="phone_btnWrapp" href="tel:+38 044 593 66 33">-->
<!--      <strong><p class="link_text">Замовити <br/> консультацію</p></strong>-->
<!--      <img class="phone_btn" src="../../assets/phone_btn.png"/>-->
<!--    </a>-->
  </div>
</template>
<script>
export default {
  name: 'app_header',
  data() {
    return {
      showPhoneBtnArr: ['cache', 'cache-calculator']
    }
  },
  methods: {
    isRouteIncludesPhoneBtn(name) {
      return this.showPhoneBtnArr.includes(name);
    },
  }
}
</script>
<style lang="scss">
.header {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;

  .logoWrapper {
    width: 150px;
    margin: 5px;
    cursor: pointer;
  }

  .phone_btn {
    width: 55px;
    height: 55px;
    margin: 5px 5px;
  }

  .phone_btnWrapp {
    display: flex;
    gap: 10px;
    cursor: pointer;
    text-align: center;
  }

  .link_text {
    color: #0e0e0e;
  }
}

@media (max-width: 420px) {
  .header {
    .phone_btn {
      width: 45px;
      height: 45px;
      margin: 5px 5px;
    }

    .phone_btnWrapp {
      display: flex;
      gap: 10px;
      cursor: pointer;
      text-align: center;
      font-size: 12px;
    }
  }
}
</style>
