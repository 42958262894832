<template>
  <div class="app">
    <div class="container" v-if="showWaitingPage">
      <h2>Отримую дані, почекайте...</h2>
      <picture>
        <img src="../src/assets/Rocket.gif" alt="wait_gif"/>
      </picture>
    </div>
    <HeaderLogo v-if="!showWaitingPage"/>
    <router-view v-if="!showWaitingPage"/>
    <WaitingModalWindow v-if="!showWaitingPage && showWaitingOverlayPreloader" />
    <div class="container" v-if="unactivated">
      <h2>Це посилання не є дійсним!</h2>
      <p>Можливо, Ви перейшли за хибним посиланням, або термін цього посилання вже сплив</p>
      <div>Але Ви можете подати заявку на отримання кеш-кредиту</div>
      <a
          class="btn primary cash-btn"
          href="/cash"
      >
        <div>Оформити</div>
      </a>
      <img src="../src/assets/sad.gif" alt="wait_gif" class="sad_picture"/>
    </div>
  </div>
</template>

<script>
import HeaderLogo from "@/components/common/HeaderLogo";
import {mapActions, mapGetters, mapMutations} from "vuex";
import redirectHandlerMixin from "@/mixins/redirectHandlerMixin";
import WaitingModalWindow from "@/components/common/WaitingModalWindow.vue";
import redirectRoutesMixin from "@/mixins/redirectRoutesMixin";

const VUE_APP_MAINTENANCE_MODE = process.env.VUE_APP_MAINTENANCE_MODE;
export default {
  name: 'App',
  data() {
    return {
      invToken: '',
      sessionToken: '',
      showWaitingPage: false,
      unactivated: false
    };
  },
  mixins: [redirectHandlerMixin, redirectRoutesMixin],

  methods: {
    ...mapActions([
      'activateInvitation',
      'getInvitationData',
      'checkTokenStage',
    ]),
    ...mapMutations([
      'SET_OVERLAY_PRELOADER',
    ]),
    async checkQueryForToken() {
      let promise = new Promise((resolve) => {
        setTimeout(() => {
          if (this.$route.query.token !== '' && this.$route.query.token !== null && this.$route.query.token !== undefined) {
            this.invToken = this.$route.query.token
          }
          if (this.$route.name === 'cache' || this.$route.name === 'cash') {
            this.redirectHandler('/cash', '');
            this.showWaitingPage = false;
            return
          } else if(this.$route.name === 'join' ) {
            this.redirectHandler('/join', '');
            this.showWaitingPage = false;
            return;
          }
          if (this.$route.query.token === undefined && this.$route.query.sid === undefined) {
            this.redirectHandler('/error', '');
            this.showWaitingPage = false;
          }
          resolve(this.$route.query.token);
        }, 500)
      })
      return await promise;
    },

    redirectAfterActivation(token) {
      const timer = setTimeout(() => {
        this.checkTokenStage(token).then((data) => {
          if (data.stage.code !== 'new') {
            this.checkNewStageForRedirect(data.stage.code, token);
            clearTimeout(timer);
          }    // ToDo: додати тостер якщо сервер знову поверне NEW
        })

      }, 500)
    },
    async checkForInvTokenInStorage() {
      const tokenfromStorage = localStorage.getItem('VUE_APP_TOKEN');
      let promise = new Promise((resolve) => {
        setTimeout(() => {
          if (tokenfromStorage && tokenfromStorage !== this.invToken) {
            localStorage.removeItem('VUE_APP_X_TOKEN');
          }
          resolve()
        }, 300)
      });

      return await promise;
    },

  async mainFunction() {
    await this.checkQueryForToken();

    if(VUE_APP_MAINTENANCE_MODE !== null && VUE_APP_MAINTENANCE_MODE.toLowerCase().trim() === 'true') {
      this.redirectHandler('/maintenance')
      return
    }

    if (!this.$route.query.sid) {
      await this.checkForInvTokenInStorage();
    }
    const X_Session_token = localStorage.getItem('VUE_APP_X_TOKEN');

    if (!X_Session_token) {
      await this.activateInvitation(this.invToken).catch(() => {
        this.unactivated = true
      })
    }

    if (this.invToken !== '' && this.invToken !== null && this.invToken !== undefined && !this.unactivated) {
      localStorage.setItem('VUE_APP_TOKEN', this.invToken);
      this.checkTokenStage(this.invToken).then((data) => {
        if (data.stage.code === 'new') {
          this.activateInvitation(this.invToken);
          this.redirectAfterActivation(this.invToken);
        } else {
          this.getInvitationData({invToken: this.invToken, clientDataFilled: this.clientDataFilled});
          this.checkNewStageForRedirect(data.stage.code, this.invToken);
        }
      })
    }
    this.showWaitingPage = false;
  },
},

  components: {
    HeaderLogo,
    WaitingModalWindow
  },
  mounted() {
    this.showWaitingPage = true;
    this.mainFunction();
  },

  computed: {
    ...mapGetters([
      'clientError',
        'showWaitingOverlayPreloader'
    ]),
    tokenInv() {
      return this.$route.query.token
    },
  },
};
</script>
<style lang="scss">

html, body {
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
 // background-color: rgb(65, 65, 65);
}

a {
  text-decoration: none;
}

#app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.app{
  .container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
      .sad_picture {
        width: 100px;
      }
      p {
        margin-bottom: 1em;
      }
      .cash-btn {
        margin: 20px 0px 20px;
      }
      .btn {
        padding: 10px 25px;
        border-radius: 5px;
        font-family: Montserrat, sans-serif;
        border: 1px solid transparent;
        color: white;
        font-weight: bold;
        cursor: pointer;
        display: flex;
        align-items: center;

        .buttonIcon{
          max-width: 30px;
          width: 100%;
          margin-left: 10px;
        }
      }

      .primary {
        background: linear-gradient(0.25turn, #3cc43c, #6dcb6d);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
  }
}


</style>

