const VUE_APP_BACKEND_API_URL = process.env.VUE_APP_TK_ONLINE_BASE_URL;
// const VUE_APP_BACKEND_API_URL = 'https://api.stage.lendiq.app/';
const auth_id = 'EkvuQrvwkXOV2RS75h65movGTslRclr8';
export const sign_key = 'lII2ZsLWIlHj2RbpPFEUfy7htvSlTUj8OBiqITi7kDUTYcV0E4Fgg8inCXYCd6OBFVYS6S1PZzZuFbQnpSFNIGp4bxOvYwmHyEnR3nKnooOELBGg8wGs7sPCcZMjgQA3';
import session from './session';
// const X_Session_token = localStorage.getItem('VUE_APP_X_TOKEN');

export const TK_API = {
    activateInvitation(token) {
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/activate_invitation/${token}`)
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    getInvitationData(token) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/get_invitation_data/${token}`, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    checkTokenStage(token) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/get_invitation_stage/${token}`, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    getBankIdVerification(sid) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/bankid/success?sid=${sid}`, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    updateTokenStage(token, action) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/apply_data`,
            {
                "invitation": `${token}`,
                "payload": {
                    "action": `${action}`,
                    "data": {}
                }
            }, {
                headers: {
                    'X-Session': `${x_token}`
                }
            })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    signOrderWithOtp(token, code) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/apply_data`,
            {
                "invitation": `${token}`,
                "payload": {
                    "action": 'check',
                    "data": {'code': code.toString()}
                }
            }, {
                headers: {
                    'X-Session': `${x_token}`
                }
            })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    getInvitationFiles(token) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/get_invitation_files/${token}`, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    getFile(token, fileToken) {
        return new Promise((res) => {
            session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/get_file/${token}/${fileToken}`)
                .then((response) => {
                    res(response);
                })
                .catch((err) => {
                    console.error(err);
                });
        })
    },
    getNewOtp(token) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/apply_data`, {
            "invitation": `${token}`,
            "payload": {
                "action": 'resend',
                "data": {}
            }
        }, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    createLinkForApplicationFormCacheOnline(payload) {
        const username = "client";
        const password = "Fjz9r2mRM8JNZcwNCxup";
        const credentials = username + ":" + password;
        const encodedCredentials = btoa(credentials);

        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/backend/create_link`, {
            "auth": {
                "auth_id": `${auth_id}`,
                "salt": `${payload.salt}`,
                "sign": `${payload.sign}`
            },
            "payload": {
                "order": {
                    "seller": "cache_seller"
                },
                "product": {
                    "product": "CACHE",
                    "variant": "CACHE_ONLINE"
                },
                "lead": {
                    "lead_id": payload.lead_id
                },
                "meta": {
                    "link_path": payload.link_source
                }
            }
        }, {
            headers: {
                Authorization: `Basic ${encodedCredentials}`
            }
        })
    },
    getCreditCalculatorLimits(token){
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/get_invitation_properties/${token}`, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    sendCalculatorSettingsForResult(payload) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/calculator`, {
            "invitation": `${payload.token}`,
                "payload": {
                "credit_length": payload.length,
                "credit_value": payload.amount,
                "first_pay": payload.first_pay
            }
        }, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    // eslint-disable-next-line no-empty-pattern
    submitCreditValues(payload) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/apply_data`,
            {
                "invitation": `${payload.token}`,
                "payload": {
                    "action": "apply_terms",
                    "data": {
                        "credit_length": `${payload.credit_length}`,
                        "credit_value": `${payload.credit_value}`,
                        "first_pay": `${payload.first_pay}`
                    }
                }
            }, {
                headers: {
                    'X-Session': `${x_token}`
                }
            })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    getIpayLink(token) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/apply_data`,
            {
                "invitation": `${token}`,
                "payload": {
                    "action": "get_ipay_link",
                    "data": {}
                }
            }, {
                headers: {
                    'X-Session': `${x_token}`
                }
            })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },
    sendPhoneNumberToCheck(payload) {
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/check_phone`,
            {
                "phone_number": payload.number
            })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },

    sendPhoneConfirmationAction(payload) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/confirm_phone/apply`,
            {
                "invitation": payload.token,
                "payload": {
                    "action": payload.action,
                    "data": {
                        "value": payload.value
                    }
                }
            }, {
                headers: {
                    'X-Session': `${x_token}`
                }
            })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },

    sendNpsAcceptedAction(payload) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.post(`${VUE_APP_BACKEND_API_URL}app/api/v1/nps/apply`,
            {
                "invitation": payload.token,
                "payload": {
                    "action": "continue",
                    "data": payload.data
                }
            }, {
                headers: {
                    'X-Session': `${x_token}`
                }
            })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err);
            });
    },

    getJoinByRefData(payload) {
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/join_by_ref/get_data/${payload}`)
            .then((response) => response.data)
            .catch((err) => {
                console.error(err)
            })
    },

    getFinMonPayload(payload) {
        let x_token = localStorage.getItem('VUE_APP_X_TOKEN');
        return session.get(`${VUE_APP_BACKEND_API_URL}app/api/v1/get_fin_mon_payload/${payload}`, {
            headers: {
                'X-Session': `${x_token}`
            }
        })
            .then((response) => response.data)
            .catch((err) => {
                console.error(err)
            })
    }
}


