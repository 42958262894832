import redirectHandlerMixin from "@/mixins/redirectHandlerMixin";

export default {
    mixins:[redirectHandlerMixin],
    methods: {
        checkNewStageForRedirect(stage, invToken) {
            switch (stage)  {
                case "credit-info":
                    this.redirectHandler('/credit-info', {token: invToken});
                break;
                case "identification":
                    this.redirectHandler('/identification', {token: invToken});
                    break;
                case "signing":
                    this.redirectHandler('/signing', {token: invToken});
                    break;
                case "done-ok":
                    this.redirectHandler('/signing-success', {token: invToken});
                    break;
                case "identification-done":
                    this.redirectHandler('/join/bankid/success', {token: invToken});
                    break;
                case "doc-prepare":
                    this.redirectHandler('/join/bankid/success', {token: invToken});
                    break;
                case "signed-docs-prepare":
                    this.redirectHandler('/join/bankid/success', {token: invToken});
                    break;
                case "rejected":
                    this.redirectHandler('/rejected');
                    break;
                case "payment-failed":
                    this.redirectHandler('/payment-failed');
                    break;
                case "cache-calculator":
                    this.redirectHandler('/cache-calculator', {token: invToken});
                    break;
                case "credit-calculator":
                    this.redirectHandler('/credit-calculator', {token: invToken});
                    break;
                case "bank-card":
                    this.redirectHandler('/bank_card', {token: invToken});
                    break;
                case "mobile-number":
                    this.redirectHandler("/mobile_number", {token: invToken});
                    break;
                case "wait":
                    this.redirectHandler('/wait', {token: invToken});
                    break;
                case "draft":
                    this.redirectHandler('/wait', {token: invToken});
                    break;
                case "nps":
                    this.redirectHandler('/nps', {token: invToken});
                    break
                case "fin-mon-online":
                    this.redirectHandler('/fin-mon-online', {token: invToken});
                    break
                case "stopped":
                    this.redirectHandler('/stopped', {token: invToken});
                    break
                default:
                    this.redirectHandler('/404');
            // ToDo: 404 page
        }
        }
    }
}
