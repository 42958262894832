<template>
  <div>
  <div class="pageOverlay"></div>
    <div class="modal">
      <img class="img_loading" src="../../assets/ZZ5H.gif"  alt="loading_gif"/>
      <h1>{{waitingText}}</h1>
    </div>

  </div>
</template>

<script>



export default {
  computed: {
    waitingText() {
      return this.$store.state.waitingOverlayCustomText;
    },
  },
  methods:{
    // waitingTextHandler() {
    //   if(this.waitingText !== ""){
    //     return this.waitingText;
    //   }
    //     return "зачекайте..."
    //
    // }
  }
}
</script>
<style lang="scss" scoped>
.pageOverlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0; top: 0; right: 0; bottom: 0;
  background: #070000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 80%;
  z-index: 7;

}

.modal {
  position: absolute;
  left: 50%;
  top: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 150px;
  transform: translate(-50%, -50%);
  color: #edf3ee;
  z-index: 10;
}
.img_loading{
  width: 80px;
}
</style>
