import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)


const routes = [
    {
        path: '/credit-info',
        name: 'step_one',
        component: () => import(/* webpackChunkName: "step_one" */ '../views/FirstStep.vue')
    },
    {
        path: '/identification',
        name: 'step_two',
        component: () => import(/* webpackChunkName: "step_two" */ '../views/SecondStep.vue')
    },
    {
        path: '/signing',
        name: 'step_three',
        component: () => import(/* webpackChunkName: "step_two" */ '../views/ThirdStep.vue')
    },
    {
        path: '/signing-success',
        name: 'signing_success',
        component: () => import(/* webpackChunkName: "step_success" */ '../views/SuccessPage.vue')
    },
    {
        path: '/singing-fail',
        name: 'singing_fail',
        component: () => import(/* webpackChunkName: "step_fail" */ '../views/FailSingingPage.vue')
    },
    {
        path: '/join/bankid/success',
        name: 'bid-success',
        component: () => import(/* webpackChunkName: "step_BIdsuccess" */ '../views/BanId_stages/SuccessBankIdPage.vue')
    },
    {
        path: '/join/bankid/fail',
        name: 'bid-fail',
        component: () => import(/* webpackChunkName: "step_BIdfail" */ '../views/BanId_stages/FailBankIdPage.vue')
    },
    {
        path: '/ipay/success',
        name: 'success',
        component: () => import(/* webpackChunkName: "step_BIdfail" */ '../views/IpayStages/SuccessIpay.vue')
    },
    {
        path: '/ipay/fail',
        name: 'fail',
        component: () => import(/* webpackChunkName: "step_BIdfail" */ '../views/IpayStages/SuccessIpay.vue')
    },
    {
        path: '/error',
        name: 'error_token',
        component: () => import(/* webpackChunkName: "step_BIdfail" */ '../views/ErrorToken.vue')
    },
    {
        path: '/rejected',
        name: 'rejected',
        component: () => import(/* webpackChunkName: "step_BIdfail" */ './../views/RejectPage/RejectPage.vue')
    },
    {
        path: '/payment-failed',
        name: 'paymentFailed',
        component: () => import(/* webpackChunkName: "step_BIdfail" */ './../views/FailedPaymentPage.vue')
    },
    {
        path: '/cache-calculator',
        name: 'cache-calculator',
        component: () => import(/* webpackChunkName: "CreditCalk" */ '../views/CreditCalculator/CreditCalculator.vue')
    },
    {
        path: '/credit-calculator',
        name: 'credit-calculator',
        component: () => import(/* webpackChunkName: "CreditCalk" */ '../views/CreditCalculator/CreditCalculator.vue')
    },
    {
        path: '/cache',
        name: 'cache',
        component: () => import(/* webpackChunkName: "CreditCalk" */ '../views/Cache/CachePage.vue')
    },
    {
        path: '/cash',
        name: 'cash',
        component: () => import(/* webpackChunkName: "CreditCalk" */ '../views/Cache/CachePage.vue')
    },
    {
        path: '/bank_card',
        name: 'bank_card',
        component: () => import(/* webpackChunkName: "CreditCalk" */ '../views/BankCard/BankCard.vue')
    },
    {
        path: '/mobile_number',
        name: 'mobile_number',
        component: () => import(/* webpackChunkName: "step_MobileNumber" */ '../views/MobileNumber/MobileNumberPage.vue')
    },
    {
        path: '/stopped',
        name: 'stopped',
        component: () => import(/* webpackChunkName: "step_Stopped" */ '../views/StoppedPage/StoppedPage.vue')
    },
    {
        path: '/nps',
        name: 'nps',
        component: () => import('../views/Nps/NpsPage.vue')
    },
    {
        path: '/join',
        name: 'join',
        component: () => import('../views/Join/JoinPage.vue')
    },
    {
        path: '/maintenance',
        name: 'maintenance',
        component: () => import('../views/MaintenancePage.vue')
    },
    {
        path: "/fin-mon-online",
        name: 'fin-mon-online',
        component: () => import('../views/FinMon/FinMonOnlinePage.vue')
    },
    {
        path: '/wait',
        name: 'wait',
        component: () => import('../views/WaitPage.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router
